import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  createTimezoneSelectionViewModel,
  memoizedTimezoneSelectionViewModel,
} from '../timezoneSelectionViewModel/timezoneSelectionViewModel';
import {
  createNoAvailableSlotsViewModel,
  memoizedNoAvailableSlotsViewModel,
} from '../noAvailableSlotsViewModel/noAvailableSlotsViewModel';
import {
  createTimeSlotsSelectionViewModel,
  memoizedTimeSlotsSelectionViewModel,
  TimeSlotsSelectionViewModel,
} from '../timeSlotsSelectionViewModel/timeSlotsSelectionViewModel';
import {
  createTimeSlotsNotificationViewModel,
  memoizedTimeSlotsNotificationViewModel,
} from '../timeSlotsNotificationViewModel/timeSlotsNotificationViewModel';
import {
  createWeekPickerViewModel,
  memoizedWeekPickerViewModel,
} from '../weekPickerViewModel/weekPickerViewModel';
import {
  getTimeSlotsAvailabilityStatuses,
  filterTimeSlotsAvailabilityStatusesByDate,
  TimeSlotAvailabilityStatus,
} from '../../../../utils/timeSlots/timeSlots';
import { WeeklyTimeSlotsLayoutViewModel } from '../bodyViewModel/bodyViewModel.types';
import {
  getSlotsPerDaysInSelectedRange,
  SlotsFactory,
} from '../../../../utils/slotsPerDay/slotsPerDay';

export const memoizedWeeklyTimeSlotsLayoutViewModel: MemoizedViewModalFactory<weeklytimeslotslayoutviewmodel> =
  {
    dependencies: {
      settings: ['dateAndTimeSectionHeader', 'calendarLayout'],
      state: [
        'slotsStatus',
        'availableSlots',
        'selectedRange',
        'selectedTimezone',
        'selectedTime',
      ],
      subDependencies: [
        memoizedTimezoneSelectionViewModel.dependencies,
        memoizedTimeSlotsNotificationViewModel.dependencies,
        memoizedWeekPickerViewModel.dependencies,
        memoizedNoAvailableSlotsViewModel.dependencies,
        memoizedTimeSlotsSelectionViewModel.dependencies,
      ],
    },
    createViewModel: createWeeklyTimeSlotsLayoutViewModel,
  };

export function createWeeklyTimeSlotsLayoutViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): WeeklyTimeSlotsLayoutViewModel {
  const { getContent, settingsParams } = context;
  const { slotsStatus, availableSlots, selectedRange } = state;

  let slotsPerDays;
  let timeSlotsNotificationViewModel;
  let weekPickerViewModel;
  let noAvailableSlotsViewModel;
  if (selectedRange) {
    const timeSlotsAvailabilityStatuses: Map<
      string,
      TimeSlotAvailabilityStatus
    > = getTimeSlotsAvailabilityStatuses(availableSlots?.availabilityEntries);

    const slotsFactory = createTimeSlotsFactory(timeSlotsAvailabilityStatuses);
    slotsPerDays = getSlotsPerDaysInSelectedRange<timeslotsselectionviewmodel>({
      slotsFactory,
      state,
      context,
    });

    timeSlotsNotificationViewModel = createTimeSlotsNotificationViewModel({
      timeSlotsAvailabilityStatuses,
      state,
      context,
    });

    weekPickerViewModel = createWeekPickerViewModel({ state, context });

    noAvailableSlotsViewModel = createNoAvailableSlotsViewModel({
      state,
      context,
    });
  }

  return {
    bodyTitle: getContent({
      settingsParam: settingsParams.dateAndTimeSectionHeader,
      translationKey: 'app.settings.defaults.widget.date-and-time-header',
    }),
    slotsStatus,
    timezoneSelectionViewModel: createTimezoneSelectionViewModel({
      state,
      context,
    }),
    weekPickerViewModel,
    timeSlotsNotificationViewModel,
    slotsPerDays,
    noAvailableSlotsViewModel,
  };
}

export const createTimeSlotsFactory =
  (
    timeSlotsAvailabilityStatuses: Map<string, TimeSlotAvailabilityStatus="">,
  ): مصنع الفتحات<timeslotsselectionviewmodel> =>
  (
    dateAsLocalDate: string,
    state: CalendarState,
    context: CalendarContext,
  ): TimeSlotsSelectionViewModel => {
    const { selectedTime } = state;
    const filteredTimeSlotsAvailabilityStatuses =
      filterTimeSlotsAvailabilityStatusesByDate(
        timeSlotsAvailabilityStatuses,
        dateAsLocalDate,
      );

    const firstTimeSlot = timeSlotsAvailabilityStatuses.keys().next()
      .value as string;
    const shouldHighlightedSlotDetailsOnRender =
      filteredTimeSlotsAvailabilityStatuses.has(firstTimeSlot) && !selectedTime;
    return createTimeSlotsSelectionViewModel({
      timeSlotsAvailabilityStatuses: filteredTimeSlotsAvailabilityStatuses,
      shouldHighlightedSlotDetailsOnRender,
      state,
      context,
    });
  };
</timeslotsselectionviewmodel></string,></timeslotsselectionviewmodel></weeklytimeslotslayoutviewmodel>